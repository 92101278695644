import SeriesCard from "@/components/cards/SeriesCard"
import { trpc } from "@/web/clients/trpc"
import { Button } from "@/ui/button"
import { Icon } from "@/ui/icon"
import { motion } from "framer-motion"

const HomepageTrendingSeries = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getTrendingSeries.useQuery()

  return (
    <section className="py-12">
      <h2
        className={`
          flex items-center justify-between text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        Series
        <a href="/explore?tab=series">
          <Button kind="tertiarty" size="sm" uppercase>
            <span
              className={`
                hidden
                lg:block
              `}
            >
              See All
            </span>{" "}
            <Icon name="arrowRight" className="lg:hidden" invertOnDark />
          </Button>
        </a>
      </h2>

      {isLoading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`
            grid grid-cols-1 gap-8 pt-6
            lg:grid-cols-3 lg:pt-8
          `}
        >
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
        </motion.div>
      ) : (
        <motion.div
          key="loaded"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`
            grid grid-cols-1 gap-8 pt-6
            lg:grid-cols-3 lg:pt-8
          `}
        >
          {data
            ?.filter((series) => series.collection.name)
            .map((series) => (
              <SeriesCard key={series.token_id} series={series} />
            ))}
        </motion.div>
      )}
    </section>
  )
}

export default HomepageTrendingSeries
