import GridCard from "@/components/cards/GridCard"
import { Erc721TokenExtended } from "@/components/cards/base"
import { trpc } from "@/web/clients/trpc"
import { Button } from "@/ui/button"
import { Icon } from "@/ui/icon"
import { motion } from "framer-motion"

const HomepageLiveAuctions = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getLiveAuctions.useQuery()

  if (data?.nft_auctions?.length === 0 && !isLoading) {
    return <></>
  }

  return (
    <section className="py-12">
      <h2
        className={`
          flex items-center justify-between text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        Live Auctions
        <a href="/explore?artworks[auction.auctionState][]=RUNNING_AUCTION&tab=artworks">
          <Button kind="tertiarty" size="sm" uppercase>
            <span
              className={`
                hidden
                lg:block
              `}
            >
              See All
            </span>{" "}
            <Icon name="arrowRight" className="lg:hidden" invertOnDark />
          </Button>
        </a>
      </h2>
      {isLoading ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="grid grid-cols-3 gap-8 pt-8"
        >
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
          <div
            className={`
              h-96 w-full animate-pulse rounded-md bg-sr-200
              dim:bg-sr-700
            `}
          />
        </motion.div>
      ) : (
        <motion.div
          key="loaded"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={`
            grid grid-cols-1 gap-8 pt-6
            lg:grid-cols-3 lg:pt-8
          `}
        >
          {data?.nft_auctions?.map((artwork) => (
            <div key={artwork.token_id}>
              <GridCard
                kind="showcase"
                artwork={artwork.erc721_token as Erc721TokenExtended}
              />
            </div>
          ))}
        </motion.div>
      )}
    </section>
  )
}

export default HomepageLiveAuctions
