"use client"

import { Erc721Token } from "@/graphql/types/Erc721Token"
import { User } from "@/graphql/generated/apollo/graphql"
import Countdown from "react-countdown"
import Image from "next/image"
import Link from "next/link"
import { cn } from "@/utils/cn"
import { formatUnits, zeroAddress } from "viem"
import { Price } from "@/server/lib/series/series.types"
import { heapTrack } from "@/utils/heap"
import { UserList } from "../profile/UserList"
import UserProfileDialog from "./UserProfileDialog"
import { PartialPick } from "@/utils/types"
import { useToggle } from "react-use"
import { Dialog } from "@/ui/dialog"
import { AvatarProfile } from "@/ui/avatarProfile"
import { FollowOrUnfollowButton } from "../profile/User"
import makeBlockie from "ethereum-blockies-base64"
import { getCurrencyByAddress } from "@/utils/currency"

export const ArtworkMedia = ({
  image,
  image_blurred,
  children,
  video,
  href,
  isActive,
  page,
}: {
  image?: string
  image_blurred?: string
  video?: string
  children?: React.ReactNode
  href?: string
  isActive?: boolean
  page?: string
}): JSX.Element => {
  return (
    <a href={href} onClick={() => heapTrack(`${page} - artwork clicked`)}>
      <div
        className={`
        relative flex aspect-square items-center justify-center overflow-hidden rounded-md bg-sr-bg-tertiary
      `}
      >
        {video ? (
          <video playsInline src={video} muted autoPlay loop />
        ) : (
          image && (
            <Image
              className={`
                bg-cover duration-300
                ${isActive ? "" : "group-hover:scale-105"}
              `}
              placeholder="blur"
              blurDataURL={image_blurred || image}
              src={image}
              fill
              alt={""}
              style={{ objectFit: "cover" }}
            />
          )
        )}
        {children}
      </div>
    </a>
  )
}

export const ArtworkListPrice = ({
  title,
  price,
}: {
  title: string
  price?: Price
}): JSX.Element => {
  const currency = getCurrencyByAddress(price?.currency?.address || zeroAddress)
  return (
    <div>
      <div className="uppercase tracking-wide text-typography-grey-2">
        {title}
      </div>
      <div
        className={`
          text-sm uppercase tracking-wide text-black
          dark:text-white
        `}
      >
        {price
          ? `${formatUnits(BigInt(price.amount), currency?.decimals)} ${currency?.symbol}`
          : ""}
      </div>
    </div>
  )
}

export const ArtworkAuthorAvatar = ({
  user,
}: {
  user:
    | PartialPick<User, "username" | "ethereum_address" | "avatar">
    | undefined
    | null
}): JSX.Element => {
  if (!user)
    return (
      <div
        className={`
          h-7 w-7 overflow-hidden rounded-full
          focus:outline-none
        `}
      >
        <div className="flex h-full w-full items-center justify-center bg-sr-bg-primary text-sr-text-primary">
          <Image
            className={`
              h-full
              dark:bg-white dark:invert
              dim:bg-invert
            `}
            src={"/assets/avatar.svg"}
            alt={"-"}
            width={32}
            height={32}
          />
        </div>
      </div>
    )

  return (
    <div className="h-7 w-7 overflow-hidden rounded-full">
      {user.username ? (
        <Link href={`/${user.username}`}>
          <Image
            className={`
              h-full object-cover
              ${cn(!user.avatar && "dark:invert", "bg-transparent")}
            `}
            src={user.avatar || makeBlockie(user?.ethereum_address || "0x")}
            alt={user.username || "-"}
            width={32}
            height={32}
          />
        </Link>
      ) : (
        <Image
          className={`
            h-full
            ${cn(!user.avatar && "dark:invert", "bg-transparent")}
          `}
          src={user.avatar || makeBlockie(user?.ethereum_address || "0x")}
          alt={user.ethereum_address?.substring(0, 8) + "..." || ""}
          width={32}
          height={32}
        />
      )}
    </div>
  )
}

export const ArtworkUser = ({
  title = null,
  users,
  ethAddress,
  showUsername = true,
  counter = "default",
  counterValue,
  size = "md",
  onCounterClick,
  isArtist,
}: {
  title?: string | null
  users?: PartialPick<User, "username" | "ethereum_address" | "avatar">[]
  ethAddress?: string
  showUsername?: boolean
  counter?: "left" | "default" | "none"
  counterValue?: number | null
  size?: "sm" | "md"
  onCounterClick?: () => void
  isArtist?: boolean
}): JSX.Element => {
  const userList = Array.isArray(users) ? users : [users]
  const [usersDialog, toggleUsersDialog] = useToggle(false)
  return (
    <>
      <div className={cn("flex flex-col", size == "sm" && "text-sm")}>
        {title && (
          <div className="text-sm uppercase tracking-wide text-typography-grey-2">
            {title}
          </div>
        )}
        {!users || (Array.isArray(users) && users.length === 0) ? (
          <div className="flex items-center">- </div>
        ) : (
          <div className="flex items-center">
            {counter == "left" && (
              <button className="mr-2" onClick={toggleUsersDialog}>
                {counterValue || userList.length}
              </button>
            )}
            {userList.slice(0, 3).map((user, index) => (
              <div
                key={`avatar-user-${index}-${
                  user?.ethereum_address || ethAddress
                }}`}
                className={cn("flex items-center", index > 0 && "-ml-1")}
              >
                <UserProfileDialog
                  ethAddress={user?.ethereum_address || ethAddress}
                  isArtist={isArtist}
                >
                  <ArtworkAuthorAvatar
                    key={`avatar-artwork-${index}-${
                      user?.ethereum_address || ethAddress
                    }}`}
                    user={user}
                  />
                  {userList.length === 1 && showUsername && (
                    <Link href={`/${user?.username}`}>
                      <span className="ml-2">{user?.username}</span>
                    </Link>
                  )}
                </UserProfileDialog>
              </div>
            ))}
            {counter == "default" && userList.length > 3 && (
              <button
                onClick={onCounterClick}
                className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-200"
              >
                +{userList.length - 3}
              </button>
            )}
          </div>
        )}
      </div>
      <Dialog open={usersDialog} onOpenChange={toggleUsersDialog}>
        <Dialog.Content title={`Collectors (${userList.length})`}>
          <ul>
            {userList.map((user) => (
              <li
                key={user?.ethereum_address}
                className="flex justify-between py-2"
              >
                <AvatarProfile user={user} />
                {user && (
                  <FollowOrUnfollowButton
                    kind="secondary"
                    user={user as User}
                  />
                )}
                {/* todo check following status */}
              </li>
            ))}
          </ul>
        </Dialog.Content>
      </Dialog>
    </>
  )
}

export const ArtworkAuction = ({
  author,
}: {
  artwork: Erc721Token
  author: User
}): JSX.Element => {
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: number
    hours: number
    minutes: number
    seconds: number
    completed: boolean
  }): JSX.Element | number => {
    if (completed) {
      // Render a completed state
      return <>Completed</>
    } else {
      // Render a countdown
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s
        </span>
      )
    }
  }
  return (
    <div className="flex flex-col">
      <p className="uppercase tracking-wide text-typography-grey-2">
        Auction ends in
      </p>
      <div className="flex gap-x-3">
        <div
          className={`
            flex h-[32px] w-full items-center justify-center rounded-md bg-black text-center uppercase text-white
          `}
        >
          <Countdown
            date={Date.now() + 2 * 24 * 60 * 60 * 1000}
            renderer={renderer}
          />
        </div>
        <UserList
          key={`ArtworkUserAuthors-${author.id}`}
          users={author}
          showUsername={false}
        />
      </div>
    </div>
  )
}

export const ArtworkDetails = ({
  artwork,
  price,
  lastSalePrice,
}: {
  artwork: Erc721Token
  price?: Price
  lastSalePrice?: Price
}): JSX.Element => {
  return (
    <div className="flex flex-col">
      <p className="my-4 text-lg">{artwork.erc721_metadata.collection_name}</p>
      <div className="grid grid-cols-2 text-sm">
        <ArtworkListPrice title="List price" price={price} />
        <ArtworkListPrice title="Last sale price" price={lastSalePrice} />
      </div>
      <div
        className={`
          mt-4 grid grid-cols-2 border-t border-day-grey-4 py-4 text-sm
          dark:border-night-grey-4
        `}
      >
        <UserList
          key={`ArtworkUserArtist-${artwork.contract_address}`}
          title="Artist"
          users={artwork.erc721_creator.creator}
        />
        <UserList
          key={`ArtworkUserOwner-${artwork.contract_address}`}
          title="Owner"
          users={artwork.erc721_owner.owner}
        />
      </div>
    </div>
  )
}

export const ArtworkCard = ({
  artwork,
  page,
}: {
  artwork: Erc721Token
  page?: string
}): JSX.Element => {
  return (
    <article className="group flex flex-col">
      <ArtworkMedia
        image={artwork.nft_image?.image_medium}
        image_blurred={artwork.nft_image?.image_blurred_w390}
        video={artwork.nft_image?.image_video_medium}
        page={page}
      />
      <ArtworkDetails artwork={artwork} />
    </article>
  )
}
