import { useEffect, useState } from "react"
import { useToggle } from "react-use"
import { trpc } from "@/web/clients/trpc"
import { User } from "@/graphql/generated/apollo/graphql"
import toast from "react-hot-toast"
import { heapTrack } from "@/utils/heap"
import { useProfileStore } from "@/web/stores/profile"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { useAccount } from "wagmi"

export const useMuting = (user: User, onComplete?: () => void) => {
  const { isConnected } = useAccount()
  const { profile: account } = useProfileStore()
  const { openConnectModal: showDialog } = useConnectModal()
  const [isMuted, setIsMuted] = useState(false)
  const [isLoading, setIsLoading] = useToggle(false)
  const [isMutating, setIsMutating] = useToggle(false)
  const [showMutingWarning, toggleMutingWarning] = useToggle(false)

  const utils = trpc.useUtils()

  const { data: mutingData, isLoading: mutingLoading } =
    trpc.profile.isMutingArtist.useQuery(
      {
        muted_artist_address: user?.ethereum_address,
        user_id: account?.id?.toString() || "",
      },
      {
        enabled: isConnected && !!account?.id && !!user?.ethereum_address,
      }
    )

  useEffect(() => {
    setIsLoading(mutingLoading)
    if (mutingData) {
      setIsMuted(mutingData?.user_muted_artist?.muted_artist_address)
    }
  }, [mutingData, mutingLoading])

  const mutingMutation = trpc.profile.muting.useMutation({
    onSuccess: async () => {
      setIsMuted((isMuted) => !isMuted)
      toggleMutingWarning(false)
      setIsMutating(false)
      await new Promise((resolve) => setTimeout(resolve, 500)) // wait for apollo cache to update
      utils.profile.invalidate() // invalidate and refetch profile
      if (onComplete) onComplete()
    },
  })

  const handleClickMute = async () => {
    if (!isConnected || !account) {
      showDialog?.()
      return
    }

    const shallMute = !isMuted

    if (!showMutingWarning && shallMute) {
      toggleMutingWarning(true)
      return
    }

    setIsLoading(true)
    setIsMutating(true)
    try {
      await mutingMutation.mutateAsync({
        mutedArtistAddress: user?.ethereum_address,
        userId: account?.id?.toString() || "",
        muting: shallMute,
      })
      heapTrack(`${shallMute ? "Muted" : "Unmuted"} ${user?.username}!`)
      setIsMuted(shallMute)
      if (shallMute) toast.success(`Oh no! You've muted @${user?.username}!`)
      else toast(`You unmuted ${user?.username || "this user"}`)
    } catch (error) {
      toast.error("Failed to update mute status")
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading,
    isMuted,
    isMutating,
    handleClickMute,
    showMutingWarning,
    toggleMutingWarning,
  }
}
