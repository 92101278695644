import DataTable, { TableColumn } from "@/ui/dataTable"
import { Button } from "@/ui/button"
import { trpc } from "@/web/clients/trpc"
import { RouterOutput } from "@/app/api/trpc/[trpc]/infer"
import Link from "next/link"
import { cn } from "@/utils/cn"
import { motion } from "framer-motion"
import { Icon } from "@/ui/icon"
import { AvatarProfile } from "@/ui/avatarProfile"

const TableBase = ({
  title,
  subtitle,
  button,
  buttonLink,
  className,
  columns,
  data,
}: {
  title: string
  subtitle: string
  button?: string
  buttonLink?: string
  className: string
  columns: TableColumn<any>[]
  data: any[]
}): JSX.Element => {
  return (
    <div className={className}>
      <h2
        className={`
          flex items-center text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        {title}
        {buttonLink && (
          <Link href={buttonLink} className="ml-auto">
            <Button kind="tertiarty" size="sm" uppercase>
              <span
                className={`
                  hidden
                  lg:block
                `}
              >
                {button}
              </span>{" "}
              <Icon name="arrowRight" className="lg:hidden" invertOnDark />
            </Button>
          </Link>
        )}
      </h2>
      <p
        className={`
          mb-4 text-sm text-sr-text-secondary
          lg:text-base
        `}
      >
        {subtitle}
      </p>
      <DataTable columns={columns} data={data} />
    </div>
  )
}

const TableArtists = ({
  data,
}: {
  data: RouterOutput["homepage"]["getTrendingTables"]["artists"] | undefined
}): JSX.Element => {
  if (!data) return <></>
  const columns: TableColumn<(typeof data)[0]>[] = [
    {
      name: "Artists",
      selector: (row): any => (
        <AvatarProfile
          user={row.user}
          widthLimit={false}
          address={row.user.ethereum_address}
        />
      ),
    },
    {
      name: "Total Sales",
      selector: (row) => `$${Math.floor(row.totalSales ?? 0).toLocaleString()}`,
      right: true,
      sortable: true,
    },
  ]

  return (
    <TableBase
      title="Trending Artists"
      subtitle="Last 7 days"
      className=""
      columns={columns}
      data={data || []}
    />
  )
}

const TableCollectors = ({
  data,
}: {
  data: RouterOutput["homepage"]["getTrendingTables"]["collectors"] | undefined
}): JSX.Element => {
  if (!data) return <></>
  const columns: TableColumn<(typeof data)[0]>[] = [
    {
      name: "Collectors",
      selector: (row): any => (
        <>
          <AvatarProfile
            user={row.user}
            widthLimit={false}
            address={row.user.ethereum_address}
          />
        </>
      ),
    },
    {
      name: "Avg Purchase $",
      selector: (row) =>
        `$${Math.floor(row.avgPurchasePrice ?? 0).toLocaleString()}`,
      right: true,
      sortable: true,
    },
  ]

  return (
    <TableBase
      title="Top Collectors"
      subtitle="Last 7 days"
      className=""
      columns={columns}
      data={data || []}
    />
  )
}

const HomepageTrendingTables = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getTrendingTables.useQuery()

  if (isLoading)
    return (
      <motion.div
        key={"loading"}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="grid grid-cols-2 gap-16"
      >
        {Array.from({ length: 2 }).map((_, index) => (
          <div
            key={index}
            className={cn(
              index === 3
                ? "col-span-2 flex flex-col gap-2"
                : "flex flex-col gap-2"
            )}
          >
            <div
              className={`
                h-10 w-48 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                h-10 w-96 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                mt-6 h-6 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                h-6 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                h-6 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                h-6 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                h-6 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
            <div
              className={`
                h-6 animate-pulse rounded-md bg-sr-200
                dim:bg-sr-700
              `}
            ></div>
          </div>
        ))}
      </motion.div>
    )

  return (
    <motion.div
      key={"loaded"}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`
        grid grid-cols-1 gap-12
        lg:grid-cols-2
      `}
    >
      <TableArtists
        data={data?.artists.sort(
          (a, b) => (b.totalSales ?? 0) - (a.totalSales ?? 0)
        )}
      />
      <TableCollectors
        data={data?.collectors.sort(
          (a, b) => (b.worksCollected ?? 0) - (a.worksCollected ?? 0)
        )}
      />
      {/* <TablePools data={data?.pools} /> */}
    </motion.div>
  )
}

export default HomepageTrendingTables
