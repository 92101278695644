import DataTableComponent, { TableColumn } from "react-data-table-component"
import { Loader } from "./loader"

const defaultStyle: any = {
  table: {
    style: {
      backgroundColor: "var(--bg-primary)",
      border: "1px solid var(--bg-tertiary)",
      borderRadius: "4px",
    },
  },
  headRow: {
    style: {
      zIndex: 1,
      backgroundColor: "var(--bg-tertiary)",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      boxShadow: "none",
      border: "none",
      minHeight: "40px",
      maxHeight: "40px",
    },
  },
  headCells: {
    style: {
      color: "var(--text-secondary)",
      fontSize: "12px",
      fontWeight: 400,
      textTransform: "uppercase",
    },
  },
  rows: {
    style: {
      backgroundColor: "var(--bg-primary)",
      color: "var(--text-primary)",
      border: "none",
      "&:not(:last-of-type)": {
        borderBottom: "1px solid var(--bg-tertiary)",
      },
    },
  },
  cells: {
    style: {
      minHeight: "56px",
    },
  },
  pagination: {
    style: {
      border: "none",
    },
  },
  progress: {
    style: {
      backgroundColor: "transparent",
      padding: "20px",
    },
  },
}

interface DataTableProps<T> {
  columns: TableColumn<T>[]
  data: T[]
  pagination?: boolean
  paginationPerPage?: number
  paginationRowsPerPageOptions?: number[]
  handlePerRowsChange?: (arg0: number) => void
  handlePageChange?: (arg0: { pageParam: number }) => Promise<void>
  paginationServer?: boolean
  isLoading?: boolean
  totalRows?: number
  fixedHeader?: boolean
  fixedHeaderScrollHeight?: string
  className?: string
}

export type { TableColumn }

const DataTable = <T,>({
  columns,
  data,
  pagination,
  paginationPerPage,
  paginationRowsPerPageOptions,
  handlePerRowsChange,
  handlePageChange,
  paginationServer,
  isLoading,
  totalRows,
  fixedHeader,
  fixedHeaderScrollHeight,
  className,
}: DataTableProps<T>): JSX.Element => {
  return (
    <DataTableComponent
      columns={columns}
      data={data}
      customStyles={defaultStyle}
      pagination={pagination}
      paginationPerPage={paginationPerPage}
      paginationRowsPerPageOptions={paginationRowsPerPageOptions}
      onChangeRowsPerPage={(newPerPage) => handlePerRowsChange?.(newPerPage)}
      onChangePage={(page) => handlePageChange?.({ pageParam: page || 1 })}
      paginationServer={paginationServer}
      paginationTotalRows={totalRows}
      progressPending={isLoading}
      progressComponent={<Loader kind="full" className="black" />}
      fixedHeader={fixedHeader}
      fixedHeaderScrollHeight={fixedHeaderScrollHeight}
      className={className}
    />
  )
}

export default DataTable
