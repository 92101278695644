import { trpc } from "@/web/clients/trpc"
import { motion, AnimatePresence } from "framer-motion"
import { RouterOutput } from "@/app/api/trpc/[trpc]/infer"
import { cn } from "@/utils/cn"
import { Button } from "@/ui/button"
import { Icon } from "@/ui/icon"

type ItemArticle = {
  item: RouterOutput["homepage"]["getCuratedConversations"][0]
  isLarge?: boolean
}

const ItemSkeleton = (): JSX.Element => {
  return (
    <article className="group flex flex-col">
      <div
        className={`
          h-64 animate-pulse rounded-md bg-sr-200
          dim:bg-sr-700
        `}
      />
      <h3 className="mb-1 mt-4 animate-pulse text-sr-text-primary">
        <div
          className={`
            h-6 w-3/4 rounded-md bg-sr-200
            dim:bg-sr-700
          `}
        />
      </h3>
      <div className="animate-pulse text-sm text-sr-text-tertiary">
        <div
          className={`
            h-4 w-1/2 rounded-md bg-sr-200
            dim:bg-sr-700
          `}
        />
      </div>
    </article>
  )
}

const ItemArticle = ({ item, isLarge }: ItemArticle): JSX.Element => {
  if (!item) return <></>
  return (
    <article
      className={`
        group flex items-center gap-4
        lg:items-start
      `}
    >
      <div
        className={cn(
          "relative overflow-hidden rounded-md bg-sr-bg-tertiary",
          isLarge == true
            ? `
              aspect-square w-32
              lg:aspect-[489/366] lg:w-7/12
            `
            : `
              aspect-square w-32
              lg:aspect-[228/170] lg:w-56
            `
        )}
      >
        <a href={item?.url} className="absolute inset-0" target="_blank">
          <img
            src={item?.image}
            alt={item?.title}
            className={`
              h-full w-full object-cover duration-300
              group-hover:scale-105
            `}
          />
        </a>
      </div>
      <div className="flex-1">
        <h3
          className={cn(
            `
              mb-2 text-sr-text-primary
              lg:text-lg
            `,
            isLarge && "lg:text-2xl"
          )}
        >
          <a href={item?.url} className="hover:underline" target="_blank">
            {item?.title}
          </a>
        </h3>
        {isLarge && (
          <h4
            className={`
              mb-2 hidden text-sr-text-secondary
              lg:block
            `}
          >
            {item.desc?.length > 140
              ? item.desc.slice(0, 140) + "..."
              : item.desc}
          </h4>
        )}
        <p className="text-sm text-sr-text-tertiary">{item.created}</p>
      </div>
    </article>
  )
}

const HomepageCuratedConversations = (): JSX.Element => {
  const { data, isLoading } = trpc.homepage.getCuratedConversations.useQuery()

  return (
    <section className="py-12">
      <h2
        className={`
          flex items-center justify-between text-xl text-sr-text-primary
          lg:text-3xl
        `}
      >
        Curated Conversations
        <a
          href="https://insights.superrare.com/discovery/tag/curated-conversations"
          target="_blank"
        >
          <Button kind="tertiarty" size="sm" uppercase>
            <span
              className={`
                hidden
                lg:block
              `}
            >
              See All
            </span>{" "}
            <Icon name="arrowRight" className="lg:hidden" invertOnDark />
          </Button>
        </a>
      </h2>
      <h3 className="text-sr-text-secondary">
        Interviews, insight and releases.
      </h3>

      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            key={"skeleton"}
            exit={{ opacity: 0 }}
            className={`
              grid grid-cols-1 gap-3 pt-8
              lg:grid-cols-2 lg:gap-8
            `}
          >
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </motion.div>
        ) : (
          <motion.div
            key={"editorial"}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className={`
              flex flex-col gap-3 pt-8
              lg:flex-row lg:gap-24
            `}
          >
            <div
              className={`
                w-full
                lg:w-7/12
              `}
            >
              {data && <ItemArticle item={data?.[0]} isLarge />}
            </div>
            <div
              className={`
                flex w-full flex-col gap-3
                lg:w-5/12 lg:gap-8
              `}
            >
              {data
                ?.slice(1)
                .map((post) => <ItemArticle key={post.id} item={post} />)}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  )
}

export default HomepageCuratedConversations
